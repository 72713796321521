import {
  User,
  Patients,
  List,
  Calendar,
  Financing,
  Handshake,
  QuestionMark,
  News,
  Thunder
} from '../components/v2/Icons'

const psychologistContent = [
  {
    label: 'Meu Perfil',
    icon: <User />,
    path: 'meus-dados',
    subnavbar: [
      { label: 'Meus Dados', path: 'meus-dados' },
      { label: 'Minha Senha', path: 'minha-senha' },
      // { label: 'Rendimentos', path: 'rendimentos' },
      { label: 'Meu plano', path: 'meu-plano' }
    ]
  },
  {
    label: 'Vittude Insights',
    icon: <Thunder />,
    path: 'olhar-do-psicologo',
    subnavbar: [{ label: 'Olhar do psicólogo', path: 'olhar-do-psicologo' }]
  },
  {
    label: 'Pacientes',
    icon: <Patients />,
    path: 'meus-pacientes'
  },
  {
    label: 'Consultas',
    icon: <List />,
    path: 'consultas',
    subnavbar: [
      { label: 'Consultas', path: 'consultas' },
      { label: 'Avaliações', path: 'avaliacoes' }
    ]
  },
  {
    label: 'Agenda',
    icon: <Calendar />,
    path: 'agendas',
    subnavbar: [
      { label: 'Minhas Agendas', path: 'agendas' },
      { label: 'Organizar', path: 'organizar' },
      { label: 'Calendário', path: 'calendario' }
    ]
  },
  {
    label: 'Financeiro',
    icon: <Financing />,
    path: 'painel-financeiro',
    subnavbar: [
      { label: 'Painel Financeiro', path: 'painel-financeiro' },
      { label: 'Dados Bancários', path: 'dados-bancarios' },
      { label: 'Cupons', path: 'cupons' },
      { label: 'Faturamento de Parcerias', path: 'demonstrativo' },
      { label: 'Faturamento Pacientes', path: 'patient_statement' },
      { label: 'Extrato anual de pagamentos', path: 'annual_payment_statement' }
    ]
  },
  {
    label: 'Parcerias',
    icon: <Handshake />,
    path: 'parcerias'
  },
  {
    label: 'Dúvidas frequentes',
    icon: <QuestionMark />,
    path: 'ajuda'
  }
]

export { psychologistContent }
